<template>
  <div>
    <forgot-password />
  </div>
</template>
<script>
import ForgotPassword from '@/components/system/Auth/ForgotPassword'
export default {
  components: {
    ForgotPassword,
  },
}
</script>
